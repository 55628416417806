import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              PERMITAME <span className="purple"> PRESENTARME </span>
              {/* MYSELF */}
            </h1>
            <p className="home-about-body">
              Enamorado de la programación y con el paso de los años e aprendido
              algo, a mi parecer… 🤷‍♂️
              <br />
              <br />
              Con mucha experiencia en programación con
              <i>
                <b className="purple"> Genexus 9 , Genexus 15 y 16. </b>
              </i>
              <br />
              <br />
              Ahora mismo enfocándome en &nbsp;
              <i>
                <b className="purple">Full Stack Development.</b>
              </i>
              <br />
              <br />
              Empezando por el desarrollo con
              <i>
                <b className="purple"> Frameworks Javascript Modernos </b>
              </i>
              &nbsp; como
              <i>
                <b className="purple"> Node.js y React.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>HABLEMOS!</h1>
            <p>
              Siéntete libre de <span className="purple">contactar </span>
              conmigo
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/feraldama"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-github"></i>
                </a>
              </li>
              {/* <li className="social-icons">
                <a
                  href="https://twitter.com/pitin87"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/feraldam4/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/fer.aldamam/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
