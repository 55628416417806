import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import BlogCard from "./BlogsCards";
import Particle from "../Particle";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import bodega from "../../Assets/Projects/bodega.png";
import odonto from "../../Assets/Projects/odonto.png";
import devBank from "../../Assets/Projects/devBank.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mis <strong className="purple">Trabajos </strong> Recientes
        </h1>
        <p style={{ color: "white" }}>
          Algunos de los proyectos en los que trabajé recientemente.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={odonto}
              isBlog={false}
              title="Odonto Henry"
              description="E-commerce realizado completamente con JavaScript, ReactJS, Redux, NodeJS, Express, Sequelize, Passport. Manejo de usuarios logueados y no logueados a traves de localstorage. Filtros por categorías, busqueda de productos por nombre, carga de productos por medio del usuario administrador."
              link="https://github.com/feraldama/ecommerce"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={devBank}
              isBlog={false}
              title="DevBank"
              description="Aplicación de billetera digital realizada con React-Native, Expo, Redux, Express, Sequelize. Recarga de dinero, transferencias entre cuentas, posibilidad de invitar a contactos por medio de whatsapp, genera comprobante en formato pdf, historial de transacciones, gráfica con las estadísticas de transacciones realizadas."
              link="https://github.com/feraldama/henryBank"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bodega}
              isBlog={false}
              title="Editor.io"
              description="Online code and markdown editor build with react.js. Online Editor which supports html, css, and js code with instant view of website. Online markdown editor for building README file which supports GFM, Custom Html tags with toolbar and instant preview.Both the editor supports auto save of work using Local Storage"
              link="https://github.com/soumyajit4419/Editor.io"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              link="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={eeg}
              isBlog={false}
              title="Emotion Predcition from EEG Signals"
              description="Using EEG signals from 'AMIGOS' Dataset to extract features and train a classifier that helps in detection of human emotions from Brain Signals."
              link="https://gist.github.com/soumyajit4419/dc94177bc4954752051798f88fbce1df"
            />
          </Col> */}
        </Row>
        <h1 className="project-heading">
          <strong className="purple">Trabajos </strong>Anteriores
        </h1>
        <p style={{ color: "white" }}>Trabajos realizados con Genexus</p>
        <Row style={{ justifyContent: "center" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bodega}
              isBlog={false}
              title="Punto de Venta"
              description="Punto de venta realizado con Genexus. Control de ventas, compras, stock, usuarios, reportes. Has una prueba ingresando con el usuario: admin, contraseña: admin."
              link="https://www.tecnovate.com.py/bodega"
            />
          </Col>
          {/* <Col md={4} className="blog-card">
            <BlogCard
              imgPath={algo}
              link=""
              title="Cracking Interview"
              site="gitbook.com"
            />
          </Col>
          <Col md={4} className="blog-card">
            <BlogCard
              imgPath={plant}
              link="https://medium.com/jovianml/plant-ai-c8fc95ed90e6"
              title="Plant AI"
              site="medium.com"
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
