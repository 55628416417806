import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import pdf from "../../Assets/CV_Fernando_Aldama_EN.pdf";

function Resume() {
  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <i className="fas fa-download">&nbsp;</i>Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Experiencia</h3>
            <Resumecontent
              title="FRONT-END DEVELOPER"
              date="Julio 2021 - Actualmente"
              content={[
                " Desarrollo y mantenimiento de sistemas basados en las tecnologías Javascript, ReactJS, Redux, CSS.",
              ]}
            />
            <Resumecontent
              title="PROGRAMADOR GENEXUS FREELANCER"
              date="Noviembre 2018 - Junio 2021"
              content={[
                " Desarrollo de sistemas web para control de stock, ventas, compras, usuarios, almacenes.",
                " Aqui una lista ejemplos para que puedan realizar pruebas de los productos (usuario: admin - contraseña: admin):",
                " https://www.tecnovate.com.py/bodega",
                " https://www.tecnovate.com.py/restaurant",
              ]}
            />
            <Resumecontent
              title="TÉCNICO PROGRAMADOR GENEXUS"
              date="Abril 2015 - Noviembre 2018"
              content={[
                " Mantenimiento de desarrollo de sistemas para las áreas de RRHH/Contabilidad/Tesorería.",
                " Manejo de base de datos PostgreSQL, MySQL, AS400.",
              ]}
            />
            {/* <h3 className="resume-title">Extracurricular Activities</h3>
            <Resumecontent
              title="Web Developer [Pantheon-2019 Technical Fest of BIT Mesra]"
              content={[
                "Worked on creating the frontend-end of the website using Bootstrap, Javascript.",
              ]}
            />
            <Resumecontent
              title="Web Developer [Bitotsav-2020 Technical Fest of BIT Mesra]"
              content={[
                "Operated on developing the frontend end of the website using Bootstrap, Javascript and backend APIs using Node.js",
              ]}
            /> */}
          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Educación</h3>
            <Resumecontent
              title="FULL STACK DEVELOPER"
              date="Noviembre 2020 - Marzo 2021"
              content={[
                " JAVASCRIPT",
                " HTML",
                " CSS",
                " NODE",
                " REACT",
                " REDUX",
                " SQL",
                "Cursado: 100%",
              ]}
            />
            <Resumecontent
              title="LIC. CIENCIAS INFORMÁTICAS"
              date="Inconcluso"
              content={["Cursado: 92,5%"]}
            />
            <Resumecontent
              title="Técnico en Informática"
              content={["Cursado: 100%"]}
            />
            {/* <h3 className="resume-title">Ranks and Achivements</h3> */}
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <i className="fas fa-download">&nbsp;</i>Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
